<script setup>
const props = defineProps({
  data: { type: Object, required: true },
  parent: { type: String, required: false }
})
const product = props.data
const parent = props.parent
</script>

<template>
  <div class="product-card card" :class="{ 'slider-card': parent === 'slider' }">
    <NuxtLink class='link' :to="'/products/' + product.url" >
      <div class="image">
        <DImage v-if="product.image?.thumb" :src="product.image.thumb.src" alt="Product Image"></DImage>
      </div>
      <div class="info">
        <h3 v-if="product.title">{{ product.title }}</h3>
        <p v-if="product.price.gross">{{ product.price.gross }} <span>Ft</span></p>
      </div>
    </NuxtLink>
  </div>
</template>

<style scoped lang="scss">

.product-card {
  
  .info {
    margin: 1rem 0 2rem 0;

    h3 {
      font-weight: 400;
      font-size: 1.4rem;
    }
    p {
      font-size: 1.8rem;
      font-weight: 700;
      
      span {
        font-size: 1.4rem;
        color: var(--color1);
      }
    }
  }
  
  .image {
    width: auto;
    aspect-ratio: 0.6;
    border-radius: var(--radius);
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.slider-card {

  width: 200px;
  height: 420px;

}
</style>
