<script setup>
const props = defineProps({
  img: { type: Object },
  src: { type: String },
  alt: { type: String },
})
const image = props.img;
const alt = props.alt;
const src = ref(props.src);

const dairamon = useDairamon();
const config = useRuntimeConfig();
const breakpoint = ref('');
const state = reactive({ breakpoint })


onMounted(() => {
  breakpoint.value = dairamon.getBreakpoint();
  //if (props.img) src.value = image[dairamon.getImageDimension(image)].src;
})

watch(state, () => {
  if (props.img) src.value = image[dairamon.getImageDimension(image)].src;
})

const onImageError = (e) => {
  e.target.src = '/images/defaultImage.jpg';
}

</script>

<template>
  <img :src="config.public.dairamon.assetsUrl + src" @error="onImageError" :alt="alt">
</template>

<style scoped lang="scss">

</style>
