<script setup>
const props = defineProps({
  data: { type: [Object,Array], default() {return []} },
})
// const slider = props.data;
const emit = defineEmits(['mounted']);

onMounted(() => {
    emit('mounted');
});

</script>

<template>
  <div class="slider">
    <template v-for="item in props.data">
      <slot name="card" v-bind="item"></slot>
    </template>
  </div>
</template>

<style scoped lang="scss">

.slider {
  
  height: 100%;
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  gap: 2rem;
  padding: 3rem 0;
  
  overflow: scroll;
  //scrollbar-width: thin;
  //scrollbar-color: var(--color3) var(--color4);
  
  //&:hover {
  //  scrollbar-color: var(--color1) var(--color4);
  //}
  
  &::-webkit-scrollbar {
    background: var(--color4);
    border-radius: 1rem;
    border: var(--color4);
    width: 0;
    height: .25rem;
  }
  
  &::-webkit-scrollbar-track {
    background: var(--color3);
    border-radius: 1rem;
    border: var(--color3);
    margin-block: .1rem;
    height: .5rem;
  }
  
  &::-webkit-scrollbar-thumb {
    background: var(--color1);
    border: var(--color1);
    border-radius: 1rem;
    cursor: grab;
    height: .5rem;
    
    &:hover {
      background: var(--color1);
      border: var(--color1);
      height: .8rem;
    }
  }
}


</style>
